<template>
  <div class="b-table-container">
    <div class="flex-container">
      <div class="search noprint" v-if="search_filter">
        <b-input-group size="sm">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="search-form"
            type="search"
            v-model="input_search"
            placeholder="Buscar..."
            @input="resetTableRecords"
          >
          </b-form-input>
          <b-input-group-append>
            <b-button :disabled="!input_search" @click="input_search = ''"
              >Limpiar</b-button
            >
            <div
              v-if="show_loading"
              class="spinner-border text-secondary ml-2"
              role="status"
            >
              <span class="sr-only">Cargando...</span>
            </div>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="columns-btn noprint">
        <div class="column-selector noprint" v-if="columns_display">
          <b-button
            :id="`col-display-1-${display_id}`"
            class="col-display-1"
            variant="none"
            size="sm"
            >Visualizar columnas</b-button
          >
          <b-popover
            :id="`columns-popover-1-${display_id}`"
            :target="`col-display-1-${display_id}`"
            placement="bottom"
            class="col-popover-1"
            triggers="click blur"
          >
            <div
              class="column-check noprint"
              v-for="(field, index) in $props.fields"
              :key="index"
            >
              <b-form-checkbox
                v-if="field.label != ''"
                :disabled="
                  (dynamicFields.length == 1 && field.display_column) ||
                  (dynamicFields[0].key == 'selected' &&
                    dynamicFields.length == 2 &&
                    field.display_column) ||
                  (dynamicFields[dynamicFields.length - 1].key == 'actions' &&
                    dynamicFields.length == 2 &&
                    field.display_column)
                "
                :id="`column-check-${index}`"
                v-model="field.display_column"
                name="checkbox-1"
              >
                {{ field.label }}
              </b-form-checkbox>
            </div>
          </b-popover>
        </div>
        <div
          class="column-selector noprint"
          v-if="search_filter && view_filter_by"
        >
          <b-button
            :id="`col-display-2-${display_id}`"
            class="col-display-2"
            variant="none"
            size="sm"
            >Filtrar por</b-button
          >
          <b-popover
            :id="`columns-popover-2-${display_id}`"
            :target="`col-display-2-${display_id}`"
            placement="bottom"
            custom-class="filteron-popover"
            bottom
            triggers="click blur"
          >
            <b-form-group
              v-model="sortDirection"
              label-size="sm"
              description="Deseleccionar todos para filtrar por toda la información de la tabla."
              class="mb-0 filter-by-column noprint"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                v-model="filterOn"
                :aria-describedby="ariaDescribedby"
                class="mt-1"
              >
                <div v-for="(field, index) in $props.fields" :key="index">
                  <b-form-checkbox
                    v-if="field.label != '' && !field.hidden_key"
                    :value="field.key"
                    class="check-filteron"
                    >{{ field.label }}</b-form-checkbox
                  >
                </div>
              </b-form-checkbox-group>
            </b-form-group>
          </b-popover>
        </div>
      </div>
    </div>
    <TableSkeleton
      v-if="is_loading || this.busy"
      :columns="dynamicFields ? dynamicFields.length : null"
      :rows="$props.items && $props.items.length ? $props.items.length : null"
      :table_props="{ bordered: true, striped: true }"
    ></TableSkeleton>
    <b-table
      id="generic-table"
      v-else
      class="generic-table"
      :class="{ 'no-header': hidden_table_header }"
      thead-class="b-card-style text-center"
      tfoot-class="custom-footer"
      v-bind="$props"
      v-on="$listeners"
      :busy="isBusy"
      :filter="input_search"
      :filter-included-fields="filterOn"
      :filter-function="filterCustom"
      :fields="dynamicFields"
      :per-page="per_page"
      :current-page="current_page"
      :filtered="filteredItems"
      show-empty
      small
      :bordered="default_bordered"
      :borderless="default_borderless"
      :striped="default_striped"
      responsive
      :selectable="show_select_mode"
      :select-mode="select_mode"
      :hover="default_hover"
      @row-selected="slotSelected"
      empty-text="No hay datos para mostrar."
      empty-filtered-text="No hay datos que coincidan con su búsqueda."
      primary-key="id"
      @sort-changed="slotSortChanged"
      :no-local-sorting="false"
    >
      <template #head()="{ label, field: { key, sortable } }">
        <div class="custom-th">
          {{ label }}
          <template v-if="sortable">
            <b-img
              v-if="sortBy !== key"
              :src="sortIconNeutral"
              class="sort-arrow"
            ></b-img>
            <b-img
              v-else-if="$props.sortDesc"
              :src="sortIconAsc"
              class="sort-arrow"
            ></b-img>
            <b-img v-else :src="sortIconDesc" class="sort-arrow"></b-img>

            <!-- Respaldo en caso de que se quiera cambiar a íconos de bootstrap -->
            <!-- <div v-if="sortBy !== key" class="sort-arrows">
              <b-icon icon="triangle" scale=".6"> </b-icon>
              <b-icon class="arrow-down" icon="triangle" scale=".6"> </b-icon>
            </div>
            <div v-else-if="sortDesc" class="sort-arrows">
              <b-icon icon="triangle" scale=".6"> </b-icon>
              <b-icon class="arrow-down" icon="triangle-fill" scale=".6"> </b-icon>
            </div>
            <div v-else class="sort-arrows">
              <b-icon icon="triangle-fill" scale=".6"> </b-icon>
              <b-icon class="arrow-down" icon="triangle" scale=".6"> </b-icon>
            </div> -->
          </template>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <template v-if="show_pagination" v-slot:custom-foot="row">
        <div class="footer-caption noprint" colspan="0">
          <i
            >Mostrando
            {{ row.items.length }}
            de {{ items.length }} filas en total.</i
          >
        </div>
      </template>
    </b-table>

    <b-row class="custom-row noprint" v-if="show_pagination">
      <b-col sm="5" md="6" class="per-page-col">
        <b-form-group
          label="Por página:"
          label-for="per-page-input"
          label-align-sm="right"
          label-size="sm"
          class="per-page-container"
        >
          <b-form-input
            id="per-page-input"
            v-model="per_page"
            size="sm"
            type="number"
            min="0"
            :max="items.length"
            v-b-tooltip.v-secondary.noninteractive="
              'Cantidad de elementos que se van a mostrar por página.'
            "
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- {{ items.length }} {{ per_page }}
      {{ Math.ceil(this.items.length / this.per_page) }} -->
      <b-col sm="7" md="6" class="my-1 noprint" v-if="show_pagination">
        <b-pagination
          :key="current_page"
          v-model="current_page"
          :total-rows="items.length"
          :per-page="per_page"
          :disabled="per_page == items.length"
          align="fill"
          size="sm"
          class="my-0"
          no-title
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "GenericBTable",
  components: {
    TableSkeleton: () => import("@/components/reusable/TableSkeleton"),
  },
  mixins: [BTable],
  props: {
    pagination: {
      type: Number,
      default: 20,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    hidden_headers: {
      type: Array,
    },
    show_pagination: {
      type: Boolean,
      default: false,
    },
    search_filter: {
      type: Boolean,
      default: true,
    },
    columns_display: {
      type: Boolean,
      default: true,
    },
    selection_mode: {
      type: Boolean,
      default: false,
    },
    show_select_mode: {
      type: Boolean,
      default: false,
    },
    select_mode: {
      type: String,
      default: "single",
    },
    //select_mode: single, multi, range
    view_filter_by: {
      type: Boolean,
      default: false,
    },
    delay_input: {
      type: Boolean,
      default: false,
    },
    filterCustom: {
      type: Function,
    },
    item_created_id: {
      default: generateUniqueId(),
    },
    busy: {
      default: false,
    },
    default_striped: {
      default: true,
    },
    default_bordered: {
      default: true,
    },
    default_borderless: {
      default: true,
    },
    default_hover: {
      default: false,
    },
    insert_search: {
      type: String,
      default: "",
    },
    // ocultar el header completamente
    hidden_table_header: {
      default: false,
    },
    show_skeleton_efect: {
      default: true,
    },
    item_created_index: {},
    // sort_by: {},
    display_id: {},
  },
  data() {
    return {
      per_page: this.pagination ? this.pagination : 10,
      current_page: 1,
      total_rows: 1,
      columns_selected: this.$props.fields,
      table_items: this.$props.items,
      input_search: this.insert_search,
      window_top: 0,
      filterOn: [],
      last_page: false,
      is_loading: false,
      sorted_items: null,
      show_loading: false,
      timeoutId: null,
      sortIconNeutral:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconDesc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconAsc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
    };
  },
  computed: {
    // selectColumn(){

    // },
    isBusy() {
      if (!this.$props.items) return true;
      return false;
    },
    dynamicFields() {
      let fields = [];
      if (this.selection_mode) {
        fields.push({
          key: "selected",
          label: "Seleccionar",
          thStyle: { width: "1%" },
        });
      }

      fields = this.columns_selected.filter((field) => field.display_column);
      return fields;
    },
  },
  methods: {
    slotSelected(rows) {
      this.$emit("selected", rows);
    },
    emitUpdate() {
      this.$emit("emitUpdate");
    },
    resetTableRecords() {
      if (this.input_search != "") this.current_page = 1;
    },
    scrollView() {
      /**
       * Primero se determina el elemento al cual asignar el método,
       * en este caso por referencia al id del elemento con document.getElementById.
       */
      const element = document.getElementById("generic-table");
      /**
       * "Behavior" establece la transición de la animación (auto o smooth).
       * "Block" establece el alineamiento vertical.
       * "Inline" establece el alineamiento horizontal.
       *
       *
       */
      if (this.items.length <= this.per_page) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      } else if (this.per_page && this.items.length) {
        /**
         * Math.ceil() redondea hacia arriba el número resultante, obteniendo en este
         * caso la última página en la tabla actual cuando se quiere agregar un nuevo elemento
         *
         */
        this.current_page = Math.ceil(this.items.length / this.per_page);
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    },
    last() {
      this.last_page = true;
    },
    slotSortChanged(event) {
      this.is_loading = false;
      if (!this.item_created_id) return;
      let table_sort = this.table_items.sort(
        this.dynamicSort(event.sortBy, event.sortDesc)
      );
      this.table_items = table_sort;
    },
    dynamicSort(property, sortDesc = false) {
      if (!property) return;
      const sortOrder = sortDesc ? -1 : 1;
      return function (a, b) {
        // Soportar propiedades anidadas
        const aValue = property.split(".").reduce((o, i) => o[i], a);
        const bValue = property.split(".").reduce((o, i) => o[i], b);
        // Manejar valores undefined o null
        if (aValue == null && bValue == null) return 0;
        if (aValue == null) return -1 * sortOrder;
        if (bValue == null) return 1 * sortOrder;
        // Convertir a string y comparar
        const aValueStr =
          typeof aValue === "string" ? aValue.toLowerCase() : aValue.toString();
        const bValueStr =
          typeof bValue === "string" ? bValue.toLowerCase() : bValue.toString();
        let result = aValueStr < bValueStr ? -1 : aValueStr > bValueStr ? 1 : 0;
        return result * sortOrder;
      };
    },
  },
  watch: {
    input_search: function (n, o) {
      if (this.delay_input) {
        this.show_loading = true;
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          if (n !== o) {
            this.show_loading = false;
            this.$emit("emitChangeSearchField", n);
          }
        }, 500);
      } else if (n !== o) this.$emit("emitChangeSearchField", n);
    },
    pagination: function (n, o) {
      if (n == o || n < 0 || n > 20) return;
      this.per_page = n;
    },
    filterOn: function (n, o) {
      if (n == o) return;
      this.$emit("emitChangeFilterOn", n);
    },
    item_created_id() {
      this.slotSortChanged(this.$props);
      if (!this.item_created_id) return;
      if (this.table_items) {
        this.table_items.forEach((row, index) => {
          row["row_index"] = index;
        });
        const new_item = this.table_items.find(
          (x) => x.id == this.item_created_id
        );
        if (new_item) {
          if (new_item.row_index <= this.per_page - 1) {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
              const element = document.getElementById(
                `generic-table__row_${this.item_created_id}`
              );
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }
            }, 500);
          } else {
            const max_pages = Math.ceil(
              this.table_items.length / this.per_page
            );
            if (new_item.row_index % this.per_page == 0) {
              this.current_page += 1;
              if (this.current_page >= this.max_pages)
                this.current_page = max_pages + 1;
            } else {
              this.current_page = Math.ceil(new_item.row_index / this.per_page);
              if (this.current_page >= this.max_pages)
                this.current_page = max_pages;
            }
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
              const element = document.getElementById(
                `generic-table__row_${this.item_created_id}`
              );
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }
            }, 500);
          }
        }
      }
    },
  },
  created() {
    if (this.show_skeleton_efect) this.is_loading = true;
  },
  mounted() {
    this.total_rows = this.table_items.length;
    setTimeout(() => {
      this.slotSortChanged(this.$props);
    }, 1500);
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  margin-bottom: 1em;
}
.search {
  margin: 0;
  width: 100%;
}
.col-display-1,
.col-display-2 {
  min-width: 154px;
  margin-left: 2em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.col-display-1:hover,
.col-display-2:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
  transition: all 0.3s;
}
.col-popover-1 {
  z-index: 1010 !important;
}
.column-selector {
  width: 100%;
  margin-left: 0em;
}
.column-check {
  text-align: left;
}
.filter-by-column {
  width: 100%;
}
.filter-on-check {
  width: 300px;
}
.filteron-popover {
  width: 170px;
}
.custom-control-inline {
  margin-right: 0;
}
.custom-th {
  display: flex;
  justify-content: center;
}
.custom-row {
  margin-bottom: 1rem;
}
.b-table-container {
  margin: 1rem;
}
.b-table-container >>> .b-card-style {
  background: var(--primary-color);
}
#per-page-input {
  margin-top: -1%;
  max-width: 80px;
  margin-left: 2em;
}
.per-page-container {
  display: flex;
}
.per-page-col {
  margin-top: 0.4rem;
  max-width: 320px;
}
.per-page-input-append {
  margin-top: 8%;
  max-width: 300px;
  font-size: 11pt;
}
.footer-caption {
  color: var(--secondary-color);
  font-size: 11pt;
  padding-top: 0;
  float: left;
  transform: translateX(10%);
  margin-right: -200px;
}
.b-table-container >>> .custom-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100% !important;
}
.columns-btn {
  display: flex;
  justify-content: space-around;
}
.popover {
  z-index: 10 !important;
}
.sort-arrow {
  width: 0.65rem;
  height: 1rem;
  transform: translate(20%, 20%);
}
#search-form {
  min-height: 2rem;
}
.no-header >>> .table.b-table > thead {
  display: none;
}
@media (max-width: 991px) {
  .flex-container {
    display: block;
  }
  .columns-btn {
    margin-top: 0.7em;
    display: flex;
    justify-content: space-evenly;
  }
  .column-selector {
    margin-left: 0;
    width: 50%;
  }
  .col-display-1,
  .col-display-2 {
    margin: 0;
    width: 98%;
  }
  .col-display-1 {
    margin-right: 1% !important;
  }
  .col-display-2 {
    margin-left: 1% !important;
  }
  .filteron-popover {
    width: 180px !important;
    margin: 0;
  }
  .filter-by-column {
    width: auto !important;
  }
  i {
    margin-right: auto;
  }
}
/* Css para ocultar imagen de sorting por defecto en b-table */
.b-table-container >>> .table.b-table > thead > tr > [aria-sort="none"],
.b-table-container >>> .table.b-table > thead > tr > [aria-sort="ascending"],
.b-table-container >>> .table.b-table > thead > tr > [aria-sort="descending"] {
  background-image: none !important;
}
/* Css para íconos de bootstrap */
/*
.sort-arrows {
  display: flex;
  flex-direction: column;
}
.arrow-down {
  transform: rotate(180deg) translateY(40%);
} */
@media print {
  .generic-table {
    overflow: hidden !important;
  }
}
</style>